<template>
  <div>
    <div class="filter-wrapper">
      <div>
        <v-select
          v-model="dataSelectLastDayValue"
          :options="dataSelectOptionsLastDay"
          :filterable="false"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="Last Days"
          :class="['call-select', dataSelectLastDayValue ? 'call-select--value' : null]"
          @input="$_ajax">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
        </v-select>
      </div>
    </div>
    <h2>{{ computedLabel }} (This vs Last)</h2>
    <call-card-report-detail :obj-card="dataReportStat" />
  </div>
</template>

<script>
import { REPORTS_GET_STATS_OPERATOR } from '../../../store/types/actions.types';
import CallCardReportDetail from '../../../components/common/cards/reportDetailCard';
import reportsOperatorTable from '../../../mixins/table/reportsOperatorTable';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import helper from '../../../service/helper';
import { mapGetters } from 'vuex';
import selectReportPeriod from '../../../mixins/select/selectReportPeriod';

export default {
  name: 'PageReportsDetailOperator',
  components: { CallCardReportDetail },
  mixins: [reportsOperatorTable, selectReportPeriod],
  data () {
    return {
      dataIconCheck: faCheck,
      dataReportStat: {}
    };
  },
  computed: {
    ...mapGetters({
      computedStoreCurrentUser: 'auth/currentUser'
    }),
    computedLabel () {
      return this.dataSelectLastDayValue?.label || 'Today';
    }
  },
  created () {
    if (helper.isEmpty(this.computedStoreCurrentUser)) {
      const _watchMe = this.$watch('computedStoreCurrentUser', async _ => {
        this.$_ajax();
        _watchMe();
      });
    } else {
      this.$_ajax();
    }
  },
  methods: {
    async $_ajax () {
      const { payload: { stat = {} } } = await this.$store.dispatch(`report/${REPORTS_GET_STATS_OPERATOR}`, {
        teamId: helper.getOneArray(this.computedStoreCurrentUser.teams)?.id,
        operatorId: this.computedStoreCurrentUser.id,
        params: {
          period: this.dataSelectLastDayValue?.key
        }
      });
      this.dataReportStat = stat;
      this.$forceUpdate();
    }
  }
};
</script>
